
import { Component, Vue } from '@/lib/decorator';
import { ref } from 'vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import AppFilterClearBtn from '@/layouts/partials/AppFilterClearBtn.vue';
import PaginationBlock from '@/components/Pagination.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import formValidate from '@/lib/formFactory/validate';
import { convertObjectToQueryParams, minutesToTime } from '@/lib/Utils';
import { debounceDecorator } from '@/lib/util/helpers';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getEmployeesList } from '@/api/employees';

import EmployeeFinanceModule from '@/store/employeeFinance';
import FinanceModule from '@/store/finance';
import AppTable from '@/components/ui/table/Table.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import TimeFormatter from '@/components/TimeFormatter.vue';

@Component({
  components: {
    TimeFormatter,
    AppCol,
    AppRow,
    TextNumber,
    TextDatetime,
    TextMoney,
    AppTable,
    TabsNav,
    DatePicker,
    AppFilterClearBtn,
    PaginationBlock,
    FormAutocomplete,
  },
})
export default class EmployeeFinanceMain extends Vue {
  form = ref(null);
  validate = formValidate(this.form);
  employee = this.search ?? '';
  items = [];
  searchLoading = false;

  get search() {
    return EmployeeFinanceModule.search;
  }

  get tabsNav() {
    return FinanceModule.tabsNav;
  }

  get currentTab() {
    return FinanceModule.allTabs.finance_employee?.id;
  }

  get settings() {
    return EmployeeFinanceModule.pageSettings;
  }

  get model() {
    return EmployeeFinanceModule.modalParams.dateRange.model;
  }

  get aggregationTable() {
    return EmployeeFinanceModule.aggregateTable;
  }

  get paymentsTable() {
    return EmployeeFinanceModule.paymentsTable;
  }

  get payoutsTable() {
    return EmployeeFinanceModule.payoutsTable;
  }

  selectDateStart(date: { day: number; month: string; year: string }) {
    EmployeeFinanceModule.updateDateStart(date);
  }

  selectDateEnd(date: { day: number; month: string; year: string }) {
    EmployeeFinanceModule.updateDateEnd(date);
  }

  getTime(minutes: string): string {
    return minutesToTime(Number(minutes));
  }

  @debounceDecorator(600)
  async searchEmployees(employeeName: string): Promise<void> {
    this.searchLoading = true;

    if (employeeName.length < 3) {
      this.items = [];

      return;
    }

    try {
      let filtersObject = { filters: [{ id: 'withPaidEmployee', value: '1' }] };

      if (employeeName) {
        filtersObject.filters.push({ id: 'fullName', value: employeeName });
      }

      const result = await getEmployeesList(1, '', '', `&${convertObjectToQueryParams(filtersObject)}`);

      this.items = result.rows.map((item: { fullName: string; isTest: boolean }) => ({
        ...item,
        fullNameWithTest:
          item.fullName + (item.isTest ? ' <span class="cp-badge cp-badge_test cp-margin__l--3">Test</span>' : ''),
      }));
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors ? error.response.data.errors.fields : error.response.data.message,
        type: 'fail',
      });
    }

    this.searchLoading = false;
  }

  async updateSelectedEmployee(selected: { id: string; value: string; data: { fullName: string } }) {
    selected.value = selected.data.fullName;
    await EmployeeFinanceModule.setSearch(selected as { id: string; value: string });
    await EmployeeFinanceModule.getList();
  }

  mounted() {
    FinanceModule.initTabsItems();
    EmployeeFinanceModule.initList();
  }
}
