import { TableTitle } from '@/lib/layouts/page/table.interface';

export const payoutsTableColumnTitles: TableTitle[] = [
  {
    id: 'id',
    name: '',
    visible: false,
  },
  {
    id: 'date',
    name: 'Дата',
    visible: true,
  },
  {
    id: 'fullName',
    name: 'ФИО',
    visible: false,
  },
  {
    id: 'amount',
    name: 'Выплачено',
    visible: true,
  },
  {
    id: 'is_bold',
    name: '',
    visible: false,
  },
];
