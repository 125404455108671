
import { defineComponent } from 'vue';
import { prepareMoneyFormat } from '@/lib/Utils';

export default defineComponent({
  name: 'TextMoney',
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    validatedValue(): string | number {
      return this.value || 0;
    },
  },
  methods: { prepareMoneyFormat },
});
