import { TableTitle } from '@/lib/layouts/page/table.interface';

export const paymentsTableColumnTitles: TableTitle[] = [
  {
    id: 'id',
    name: '',
    visible: false,
  },
  {
    id: 'date',
    name: 'Дата',
    visible: true,
  },
  {
    id: 'fullName',
    name: 'ФИО',
    visible: false,
  },
  {
    id: 'market',
    name: 'Объект',
    visible: true,
  },
  {
    id: 'partnerLegalName',
    name: 'Партнёр',
    visible: true,
  },
  {
    id: 'worked',
    name: 'Выработка',
    visible: true,
  },
  {
    id: 'payment',
    name: 'Заработал(а)',
    visible: true,
  },
  {
    id: 'is_bold',
    name: '',
    visible: false,
  },
];
