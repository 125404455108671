import { UITable } from '@/lib/util/tableUtils';
import {
  AggregateTableInterface,
  PaymentsTableInterface,
  PayoutsTableInterface,
} from '@/interfaces/employeeFinance.interface';
import { TableTitle } from '@/lib/layouts/page/table.interface';

export function prepareAggregateTable(params: {
  table: AggregateTableInterface;
  columnTitles: TableTitle[];
  rowTitles?: string[];
  dictionary?: Record<string, string>;
}) {
  const uiTable = new UITable({ titles: params.columnTitles });

  (params.rowTitles as string[]).forEach((rowTitle: string) => {
    const row = {
      rowName: params.dictionary ? params.dictionary[rowTitle] : '',
      balance: params.table.balance[rowTitle],
      balanceTax: params.table.balanceTax[rowTitle],
      payout: params.table.payout[rowTitle],
      debt: params.table.debt[rowTitle],
    };

    rowTitle === 'total' ? uiTable.setTotalData(row) : uiTable.addRow(row);
  });

  return uiTable
    .removeColumn('worked')

    .setProperty('balance', 'align', 'right')
    .setProperty('balanceTax', 'align', 'right')
    .setProperty('payout', 'align', 'right')
    .setProperty('debt', 'align', 'right')

    .getTable() as any;
}

export function preparePaymentsTable(params: { table: PaymentsTableInterface; columnTitles: TableTitle[] }) {
  const uiTable = new UITable({
    titles: params.columnTitles,
    rows: params.table.payments,
  });

  return uiTable
    .setProperty('worked', 'align', 'right')
    .setProperty('payment', 'align', 'right')
    .setTotalData({ payment: params.table.sum, worked: params.table.worked })
    .getTable() as any;
}

export function preparePayoutsTable(params: { table: PayoutsTableInterface; columnTitles: TableTitle[] }) {
  const uiTable = new UITable({
    titles: params.columnTitles,
    rows: params.table.payouts,
  });

  return uiTable.setProperty('amount', 'align', 'right').setTotalData({ amount: params.table.sum }).getTable() as any;
}
