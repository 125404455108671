<template>
  <span>{{ numberFormat(validatedValue) }}</span>
</template>

<script>
import { defineComponent } from 'vue';
import { numberFormat } from '@/lib/Utils';

export default defineComponent({
  name: 'TextNumber',
  props: {
    value: {
      type: [String, Number],
      default: '0',
    },
  },
  computed: {
    validatedValue() {
      return this.value || 0;
    },
  },
  methods: { numberFormat },
});
</script>
