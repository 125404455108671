import { TableTitle } from '@/lib/layouts/page/table.interface';

export const aggregationTableColumnTitles: TableTitle[] = [
  {
    id: 'id',
    name: '',
    visible: false,
  },
  {
    id: 'rowName',
    name: '',
    visible: true,
  },
  {
    id: 'worked',
    name: '',
    visible: true,
  },
  {
    id: 'balance',
    name: 'Заработал(а)',
    visible: true,
  },
  {
    id: 'balanceTax',
    name: 'С налогом',
    visible: true,
  },
  {
    id: 'payout',
    name: 'Выплачено',
    visible: true,
  },
  {
    id: 'debt',
    name: 'Задолженность',
    visible: true,
  },
  {
    id: 'is_bold',
    name: '',
    visible: false,
  },
];

export const aggregationTableRowTitles: string[] = ['total', 'beforePeriod', 'byPeriod', 'afterPeriod'];
