/**
 * Global API for finance client.
 *
 */

import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { dateBriefResponse } from '@/interfaces/dateBriefResponse.interface';

/**
 * Get date briefs list
 *
 * @param filterDate
 * @param filter
 * @param pageAmountItems
 * @param sort
 * @param page
 *
 * @access CAN_READ_DATE_BRIEF
 */
export const getDateBriefList = async (
  filterDate: string,
  filter: string,
  pageAmountItems: string,
  sort: string,
  page: string | number
) => {
  filterDate = filterDate ? `&filters[0][id]=date&filters[0][value]=${filterDate}` : '';
  const assemblerUrl = new AssemblerUrl(API.finance.getList);
  const url = assemblerUrl
    .addPage(page ? page : 1)
    .addAmount(pageAmountItems)
    .addSort(sort)
    .addFilter(filterDate + filter)
    .getUrl();
  const result = await makeGetRequest<dateBriefResponse>(url);

  return result.data.result.table;
};

/**
 * Send penalty update data
 *
 * @param data
 *
 * @access CAN_EDIT_DATE_BRIEF_PENALTY
 */
export const sendPenaltyUpdateData = async (data: {}) => {
  const url = API.finance.penaltyCreate;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * get penalty detail data
 *
 * @param employeeShift
 *
 * @access CAN_READ_DATE_BRIEF
 */
export const getPenaltyDetailData = async (employeeShift: number | string) => {
  const url = API.finance.penaltyDetail.replace('$1', employeeShift.toString());
  const result = await makeGetRequest(url);

  return result.data.result[0];
};

/**
 * Delete penalty data
 *
 * @param employeeShiftId
 *
 * @access CAN_EDIT_DATE_BRIEF_PENALTY
 */
export const deletePenaltyData = async (employeeShiftId: string | number) => {
  const url = API.finance.penaltyDelete.replace('$1', employeeShiftId.toString());
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Get finance client list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param filter
 */
export const getFinanceClientList = async (pageCurrent: number, pageAmountItems: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.finance.client);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get download link for export
 *
 * @param periodStart
 * @param periodEnd
 * @param clients
 */
export const getDownloadLink = async (periodStart: string, periodEnd: string, clients?: number[] | string[]) => {
  const formData: any = {
    periodStart: periodStart,
    periodEnd: periodEnd,
  };

  if (clients) {
    clients.forEach((clientId: number | string, index: number) => {
      formData[`clients[${index}]`] = clientId;
    });
  }

  const url = API.finance.export;
  const result = await makeFormRequest(url, formData);

  return result.data.result.link;
};

/**
 * Get employee invoices list.
 */
export const getEmployeeFinancesList = async (filters: { employeeId: string; periodStart: string; periodEnd: string }) => {
  const url = API.finance.getEmployeeInvoices
    .replace('$1', filters.employeeId)
    .replace('$2', filters.periodStart)
    .replace('$3', filters.periodEnd);
  const result = await makeGetRequest(url);

  return result.data.result;
};
