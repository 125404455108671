/**
 * Employee finance store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { AggregateTableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { modalParams, ModalParamsInterface } from './modalParams';
import { aggregationTableColumnTitles, aggregationTableRowTitles } from './aggregationTableTitles';
import { paymentsTableColumnTitles } from './paymentsTableTitles';
import { payoutsTableColumnTitles } from './payoutsTableTitles';

import { prepareAggregateTable, preparePaymentsTable, preparePayoutsTable } from './table';

import { TableTitle } from '@/lib/layouts/page/table.interface';
import moment from 'moment';

import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getEmployeeFinancesList } from '@/api/finance';
import { getDateRange } from '@/lib/Payouts';
import { PaymentsTableInterface, PayoutsTableInterface } from '@/interfaces/employeeFinance.interface';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'employeeFinance';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeeFinanceModule extends PageBaseModule {
  modalParams: ModalParamsInterface = modalParams;
  search: { id: string; value: string } = { id: '', value: '' };
  aggregateTable: TableInterface | {} = {};
  payoutsTable: TableInterface | {} = {};
  paymentsTable: TableInterface | {} = {};
  aggregateTableRowDictionary = {
    total: 'ИТОГО',
    beforePeriod: 'До выбранного периода',
    byPeriod: 'В течение выбранного периода',
    afterPeriod: 'После выбранного периода',
  };
  aggregationColumnTitles: TableTitle[] = aggregationTableColumnTitles;
  aggregationRowTitles: string[] = aggregationTableRowTitles;
  paymentsColumnTitles: TableTitle[] = paymentsTableColumnTitles;
  payoutsColumnTitles: TableTitle[] = payoutsTableColumnTitles;

  constructor(module: EmployeeFinanceModule) {
    super(module);
    const page = new PageEntity();
    page.setTitle('Сверка с исполнителем');

    this.pageSettings = page.values;
  }

  @Mutation
  SET_SEARCH(value: { id: string; value: string }) {
    this.search = value;
  }

  @Mutation
  SET_AGGREGATE_TABLE(table: TableInterface) {
    this.aggregateTable = table;
  }

  @Mutation
  SET_PAYOUTS_TABLE(table: TableInterface) {
    this.payoutsTable = table;
  }

  @Mutation
  SET_PAYMENTS_TABLE(table: TableInterface) {
    this.paymentsTable = table;
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    this.modalParams.dateRange.model.dateStart.value = date;
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    this.modalParams.dateRange.model.dateEnd.value = date;
  }

  @Action({ rawError: true })
  setSearch(value: { id: string; value: string }) {
    this.SET_SEARCH(value);
  }

  @Action({ rawError: true })
  async initList() {
    await this.initDateRange();
    await this.getList();
  }

  @Action({ rawError: true })
  initDateRange() {
    const range = getDateRange();

    this.context.commit('UPDATE_DATE_START', range.startDay);
    this.context.commit('UPDATE_DATE_END', range.endDay);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      if (!this.search.id) {
        this.context.commit('SET_AGGREGATE_TABLE', {});
        this.context.commit('SET_PAYMENTS_TABLE', {});
        this.context.commit('SET_PAYOUTS_TABLE', {});

        return;
      }
      const filters = await this.prepareDataForRequest();
      const result = await getEmployeeFinancesList(filters);
      this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  prepareDataForRequest() {
    const employeeId = this.search.id;
    let dateStart: string = this.modalParams.dateRange.model.dateStart.value;
    let dateEnd: string = this.modalParams.dateRange.model.dateEnd.value;

    dateStart = moment(dateStart, 'DD/MM/YY').format('YYYY-MM-DD');
    dateEnd = moment(dateEnd, 'DD/MM/YY').format('YYYY-MM-DD');

    return {
      employeeId: employeeId,
      periodStart: dateStart,
      periodEnd: dateEnd,
    };
  }

  @Action({ rawError: true })
  setList(tables: any) {
    this.setAggregateTable(tables.aggregateTable);
    this.setPaymentTable(tables.paymentTable);
    this.setPayoutTable(tables.payoutTable);
  }

  @Action({ rawError: true })
  setAggregateTable(table: AggregateTableApiInterface) {
    const tableInfo = prepareAggregateTable({
      table: table,
      columnTitles: this.aggregationColumnTitles,
      rowTitles: this.aggregationRowTitles,
      dictionary: this.aggregateTableRowDictionary,
    });

    this.context.commit('SET_AGGREGATE_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  setPaymentTable(table: PaymentsTableInterface) {
    const tableInfo = preparePaymentsTable({ table: table, columnTitles: this.paymentsColumnTitles });

    this.context.commit('SET_PAYMENTS_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  setPayoutTable(table: PayoutsTableInterface) {
    const tableInfo = preparePayoutsTable({ table: table, columnTitles: this.payoutsColumnTitles });

    this.context.commit('SET_PAYOUTS_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updateDateStart(date: { day: number; month: string; year: string }) {
    const dateString = [date.day, date.month, date.year].join('/');
    await this.context.commit('UPDATE_DATE_START', dateString);

    const dataEnd = this.modalParams.dateRange.model.dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = this.modalParams.dateRange.model.dateStart.value.split('.');
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp > dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_END', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateEnd(date: { day: number; month: string; year: string }) {
    const dateString = [date.day, date.month, date.year].join('/');
    await this.context.commit('UPDATE_DATE_END', dateString);

    const dataEnd = this.modalParams.dateRange.model.dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = this.modalParams.dateRange.model.dateStart.value.split('.');
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp >= dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_START', date);
    }

    await this.getList();
  }
}

export default getModule(EmployeeFinanceModule);
