/**
 * Finance common store.
 *
 */

import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import { tabsNav } from './tabs';
import userModule from '@/store/user';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const MODULE_NAME = 'finance';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FinanceModule extends PageBaseModule {
  allTabs = tabsNav;
  tabsNav: TabsNavInterface = {};

  constructor(module: FinanceModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('');
    this.pageSettings = page.values;
  }

  @Mutation
  SET_TABS_NAV(list: TabsNavInterface) {
    this.tabsNav = list;
  }

  @Action({ rawError: true })
  initTabsItems() {
    const tabsByUser: TabsNavInterface = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(tabsNav)) {
      if (userModule.canUserRead(menuItem.pathName)) {
        tabsByUser[menuItemKey] = menuItem;
      }
    }

    this.SET_TABS_NAV(tabsByUser);
  }
}

export default getModule(FinanceModule);
