export interface ModalParamsInterface {
  dateRange: {
    model: {
      dateStart: {
        key: string;
        type: string;
        title: string;
        value: string;
        action: string;
        required: boolean;
        size: number;
        mask: {
          regex: string;
          placeholder: string;
          minChars: number;
        };
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      dateEnd: {
        key: string;
        type: string;
        title: string;
        value: string;
        action: string;
        required: boolean;
        size: number;
        mask: {
          regex: string;
          placeholder: string;
          minChars: number;
        };
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
    };
  };
}

export const modalParams = {
  dateRange: {
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: '',
        value: '',
        action: 'employeeFinance/updateDateStart',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: '',
        value: '',
        action: 'employeeFinance/updateDateEnd',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
};
